import { booleanSchema } from "@deltagreen/utils"
import { createEnv } from "@t3-oss/env-nextjs"
import { z } from "zod"

const sentry = {
  ENVIRONMENT: z.string(),
  SENTRY_DSN: z
    .string()
    .url()
    .default("https://f4145584835d4df38130180e385f7f0d@o31760.ingest.sentry.io/4504048510304256"),
  SENTRY_RELEASE: z.string(),
  SENTRY_DISABLE: booleanSchema,
}

const env = createEnv({
  server: {
    MAINTENANCE_MODE: booleanSchema.default(false),
    LOG_LEVEL: z.enum(["fatal", "error", "warn", "info", "debug", "trace"]).default("info"),
    NODE_ENV: z.enum(["development", "test", "production"]),
    AUTH_SECRET: z.string().min(32),
    AUTH_SECRET_REMOTE: z.string().min(32),

    ...sentry,

    REDIS_URL: z.string().url(),
    DATABASE_URL: z.string().url(),
    DATABASE_REPLICA_URL: z.string().url(),
    AMQP_URL: z.string().url(),
    OPERATOR_URL: z.string().url(),

    NANO_MAILER_URL: z.string().url(),
    NANO_MAILER_API_KEY: z.string(),

    PROTEUS_URL: z.string().url(),

    DEVTOOLS_ENABLED: booleanSchema.default("false"),

    GOOGLE_API_KEY: z.string(),
    GOOGLE_API_URL: z.string().nullish(),

    MAPYCZ_API_KEY: z.string(),
    MAPYCZ_API_URL: z.string(),

    GOOGLE_CLOUD_PROJECT_ID: z.string().default("nano-green-1"),
    GOOGLE_CLOUD_DATA_PROJECT_ID: z.string(),
    GOOGLE_CLOUD_BASE64_CREDENTIALS: z.string(),
    GOOGLE_CLOUD_BUCKET_NAME: z.string(),

    DAKTELA_URL: z.string().url().default("https://nanoenergies.daktela.com"),
    DAKTELA_USERNAME: z.string(),
    DAKTELA_PASSWORD: z.string(),
  },

  client: {
    NEXT_PUBLIC_SENTRY_DSN: sentry.SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_RELEASE: sentry.SENTRY_RELEASE,
    NEXT_PUBLIC_SENTRY_DISABLE: sentry.SENTRY_DISABLE,
    NEXT_PUBLIC_ENVIRONMENT: sentry.ENVIRONMENT,
  },
  experimental__runtimeEnv: {
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_RELEASE: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
    NEXT_PUBLIC_SENTRY_DISABLE: process.env.NEXT_PUBLIC_SENTRY_DISABLE,
    NEXT_PUBLIC_ENVIRONMENT: process.env.NEXT_PUBLIC_ENVIRONMENT,
  },
})

export function isProd() {
  return env.ENVIRONMENT === "production"
}

export function isDev() {
  return env.ENVIRONMENT === "development"
}

export function isLocal() {
  return env.ENVIRONMENT === "local"
}

export default env
